import React, { useRef, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { flipTable, flipTableFlipped } from '../components/table'

const flip = function (text, backwards = true, upsideDown = true) {

  let convertedTxt = "";

  for (var c = (text.length - 1); c >= 0; c--) {
    var p = null;
    var g = (backwards) ? c : (-1 * (c - text.length + 1));
    var s = text.charAt(g);

    if (upsideDown) {
      p = (flipTable[s] || flipTableFlipped[s] || flipTable[s.toLowerCase()] || s);
    }
    else {
      p = s;
    }

    convertedTxt += p;
  }

  return [convertedTxt];
}

const IndexPage = () => {

  const [text, setText] = useState('')
  const [flipped, setFlipped] = useState('')
  const flippedRef = useRef()

  const onChange = e => {

    const [flipped] = flip(e.target.value)

    setFlipped(flipped)
    setText(e.target.value)
  }

  const copy = () => {

    if (flipped !== '') {
      flippedRef.current.select();
      flippedRef.current.setSelectionRange(0, 99999);

      document.execCommand("copy");

      alert("Copiado!")
    }
    else {
      alert("Escribe un teto en la caja de arriba primero!")
    }
  }

  return <Layout>
    <SEO title="Home" />
    <h2>Como escribir Letras al Reves, y poner las letras de cabeza?</h2>
    <h4>Ingresa el texto para ver las letras al reves!</h4>

    <textarea value={text} onChange={onChange} className="text" rows="5" placeholder="Ingresa el text aqui..." />

    <textarea value={flipped} ref={flippedRef} onClick={copy} className="flipped" rows="5" readOnly placeholder="Click aqui para copiar el texto" />

    <div>
      <h3>Whatsapp permitira cambiar el fondo de pantalla para cada chat por primera vez!</h3>
      <p>WhatsApp sabe que cada chat que abres es un mundo y por eso permitira cambiar el fondo de cada uno.
      Por eso creamos este nuevo sitio para que puedas descargar fondos para WhatsApp personalizados:
      </p>
      <p>
        <a href="https://www.fondosparawhatsapp.com" target="_blank">www.fondosparawhatsapp.com</a>
      </p>
    </div>

  </Layout>
}
export default IndexPage
