const flipTable = {
    "a": "\u0250",
    "b": "q",
    "c": "\u0254",
    "d": "p",
    "e": "\u01DD",
    "f": "\u025F",
    "g": "\u0183",
    "h": "\u0265",
    "i": "\u1D09",
    "j": "\u027E",
    "k": "\u029E",
    "m": "\u026F",
    "n": "u",
    "r": "\u0279",
    "t": "\u0287",
    "v": "\u028C",
    "w": "\u028D",
    "y": "\u028E",
    "A": "\u2200",
    "C": "\u0186",
    "E": "\u018E",
    "F": "\u2132",
    "G": "\u05E4",
    "H": "H",
    "I": "I",
    "J": "\u017F",
    "L": "\u02E5",
    "M": "W",
    "N": "N",
    "P": "\u0500",
    "T": "\u2534",
    "U": "\u2229",
    "V": "\u039B",
    "Y": "\u2144",
    "1": "\u0196",
    "2": "\u1105",
    "3": "\u0190",
    "4": "\u3123",
    "5": "\u03DB",
    "6": "9",
    "7": "\u3125",
    "8": "8",
    "9": "6",
    "0": "0",
    ".": "\u02D9",
    ",": "'",
    "'": ",",
    '"': ",,",
    "`": ",",
    "?": "\u00BF",
    "!": "\u00A1",
    "[": "]",
    "]": "[",
    "(": ")",
    ")": "(",
    "{": "}",
    "}": "{",
    "<": ">",
    ">": "<",
    "&": "\u214B",
    "_": "\u203E",
    "\u2234": "\u2235",
    "\u2045": "\u2046"
};

var flipTableFlipped = {
    "\u0250": "a",
    "q": "b",
    "\u0254": "c",
    "p": "d",
    "\u01DD": "e",
    "\u025F": "f",
    "\u0183": "g",
    "\u0265": "h",
    "\u1D09": "i",
    "\u027E": "j",
    "\u029E": "k",
    "\u026F": "m",
    "u": "n",
    "\u0279": "r",
    "\u0287": "t",
    "\u028C": "v",
    "\u028D": "w",
    "\u028E": "y",
    "\u2200": "A",
    "\u0186": "C",
    "\u018E": "E",
    "\u2132": "F",
    "\u05E4": "G",
    "H": "H",
    "I": "I",
    "\u017F": "J",
    "\u02E5": "L",
    "W": "M",
    "N": "N",
    "\u0500": "P",
    "\u2534": "T",
    "\u2229": "U",
    "\u039B": "V",
    "\u2144": "Y",
    "\u0196": "1",
    "\u1105": "2",
    "\u0190": "3",
    "\u3123": "4",
    "\u03DB": "5",
    "9": "6",
    "\u3125": "7",
    "8": "8",
    "6": "9",
    "0": "0",
    "\u02D9": ".",
    "'": ",",
    ",,": '"',
    ",": "`",
    "\u00BF": "?",
    "\u00A1": "!",
    "]": "[",
    "[": "]",
    ")": "(",
    "(": ")",
    "}": "{",
    "{": "}",
    ">": "<",
    "<": ">",
    "\u214B": "&",
    "\u203E": "_",
    "\u2235": "\u2234",
    "\u2046": "\u2045"
};

export { flipTable, flipTableFlipped }